import type { UserBalances } from "@/composables/balances.composable";
import {
  MAX_RATE_AREA,
  CONTOUR_STEP_PRO_BELOW,
  TERRAIN_SPACING_PRO_BELOW,
  type ExportSelection,
} from "@/constants/export-options.constant";
import Big from "big.js";
import { z } from "zod";

export const ORDERED_RATES = ["basic", "pro", "proplus"] as const;
export const RateSchema = z.enum(ORDERED_RATES);

export type Rate = z.infer<typeof RateSchema>;

export function highestRate(rates: Rate[]) {
  return ORDERED_RATES[Math.max(...rates.map((r) => ORDERED_RATES.indexOf(r)))];
}

export type Pricing = { rate: Rate; price: Big };

function computePrice(area: number, factor: number, base: number) {
  return Big(factor).mul(area).plus(base);
}

export function get2dPricing(
  area: number,
  options: ExportSelection["vectorialOptions"],
): Pricing {
  const basePricing = ((): Pricing => {
    if (options.contours && options.contourStep !== undefined) {
      if (options.contourStep <= 1) {
        return {
          rate: "pro",
          price: computePrice(area, 2.2, 14),
        };
      }

      if (options.contourStep === 2) {
        return {
          rate: "pro",
          price: computePrice(area, 1.6, 12),
        };
      }

      if (options.contourStep < CONTOUR_STEP_PRO_BELOW) {
        return {
          rate: "pro",
          price: computePrice(area, 1.4, 12),
        };
      }
    }

    if (
      options.geolocate ||
      options.trees ||
      options.shadows ||
      area > MAX_RATE_AREA.basic
    ) {
      return {
        rate: "pro",
        price: computePrice(area, 1.4, 10),
      };
    }

    return {
      rate: "basic",
      price: computePrice(area, 1.4, 10),
    };
  })();

  if (area > MAX_RATE_AREA.pro) {
    return {
      rate: "proplus",
      price: basePricing.price.mul(1.1),
    };
  }

  return basePricing;
}

export function get3dPricing(
  area: number,
  options: ExportSelection["modelingOptions"],
): Pricing {
  const basePricing = ((): Pricing => {
    const contourStep = options.contours ? options.contourStep : undefined;
    const terrainSpacing = options.terrain ? options.terrainSpacing : undefined;

    let mostPreciseOption: number | undefined = undefined;
    if (contourStep && terrainSpacing) {
      mostPreciseOption = Math.min(contourStep, terrainSpacing);
    } else if (contourStep) {
      mostPreciseOption = contourStep;
    } else if (terrainSpacing) {
      mostPreciseOption = terrainSpacing;
    }

    if (mostPreciseOption !== undefined) {
      if (mostPreciseOption <= 1) {
        return {
          rate: "pro",
          price: computePrice(area, 2.8, 16),
        };
      }

      if (mostPreciseOption === 2) {
        return {
          rate: "pro",
          price: computePrice(area, 2.6, 14),
        };
      }

      if (mostPreciseOption < CONTOUR_STEP_PRO_BELOW) {
        return {
          rate: "pro",
          price: computePrice(area, 2.2, 14),
        };
      }
    }

    if (
      terrainSpacing !== undefined &&
      terrainSpacing < TERRAIN_SPACING_PRO_BELOW
    ) {
      return {
        rate: "pro",
        price: computePrice(area, 2.2, 13),
      };
    }

    if (options.geolocate || options.trees || area > MAX_RATE_AREA.basic) {
      return {
        rate: "pro",
        price: computePrice(area, 2.2, 13),
      };
    }

    return {
      rate: "basic",
      price: computePrice(area, 2.2, 13),
    };
  })();

  if (options.format === "IFC") {
    return {
      rate: "proplus",
      price: basePricing.price.mul(2),
    };
  }

  if (area > MAX_RATE_AREA.pro) {
    return {
      rate: "proplus",
      price: basePricing.price.mul(1.1),
    };
  }

  return basePricing;
}

function getRateFromArea(area: number) {
  return area > MAX_RATE_AREA.basic
    ? area > MAX_RATE_AREA.pro
      ? "proplus"
      : "pro"
    : "basic";
}

export function getPricing(
  area: number,
  priceRatio: Big,
  selection: ExportSelection,
  balances: UserBalances,
): Pricing {
  let amount = Big(0);
  const pricings: Pricing[] = [];

  if (selection.vectorial) {
    pricings.push(get2dPricing(area, selection.vectorialOptions));
  }

  if (selection.modeling) {
    pricings.push(get3dPricing(area, selection.modelingOptions));
  }

  const globalRate: Rate = pricings.length
    ? highestRate(pricings.map((p) => p.rate))
    : getRateFromArea(area);

  const balancesToCheck: Rate[] =
    globalRate === "basic"
      ? ["basic"]
      : ORDERED_RATES.slice(ORDERED_RATES.indexOf(globalRate));

  const fullyPayWithBalance = balancesToCheck
    .map((key) => (balances[key]?.remaining ?? 0) > 0)
    .some(Boolean);

  if (!fullyPayWithBalance) {
    for (const pricing of pricings) {
      amount = amount.plus(pricing.price);
    }
  }

  return {
    rate: globalRate,
    price: amount.mul(priceRatio).round(1, Big.roundHalfUp),
  };
}
