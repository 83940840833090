import { StorageSerializers, useStorage } from "@vueuse/core";
import { LngLatBounds } from "maplibre-gl";
import {
  type ExportSelection,
  getDefaultExportSelection,
} from "@/constants/export-options.constant";

const MAP_CONTEXT_BBOX_KEY = "mapContext.bbox";
const MAP_CONTEXT_ZOOM_KEY = "mapContext.zoom";
const MAP_CONTEXT_SELECTION_KEY = "mapContext.selection";

// we do not want reactive storage,
// we just want to use local storage values as initial values
// and update them when inner app values change
// to get them back when user opens the app again

function mergeNumberProperties(selection: any, defaultSelection: any) {
  for (const key in defaultSelection) {
    if (
      typeof defaultSelection[key] === "number" &&
      selection[key] == undefined
    ) {
      selection[key] = defaultSelection[key];
    } else if (typeof defaultSelection[key] === "object") {
      mergeNumberProperties(selection[key], defaultSelection[key]);
    }
  }
}

export function useMapContext() {
  const storageBbox = useStorage<LngLatBounds | undefined>(
    MAP_CONTEXT_BBOX_KEY,
    undefined,
    localStorage,
    {
      serializer: {
        read: (raw) => {
          return new LngLatBounds(
            raw.split(",").map(parseFloat) as [number, number, number, number],
          );
        },
        write: (bounds) => {
          return bounds?.toArray().toString() ?? "";
        },
      },
      listenToStorageChanges: false,
    },
  );

  const storageZoom = useStorage<number | undefined>(
    MAP_CONTEXT_ZOOM_KEY,
    undefined,
    localStorage,
    {
      serializer: StorageSerializers.number,
      listenToStorageChanges: false,
    },
  );

  const DEFAULT_SELECTION = Object.freeze(getDefaultExportSelection());

  const storageSelection = useStorage<ExportSelection>(
    MAP_CONTEXT_SELECTION_KEY,
    DEFAULT_SELECTION,
    localStorage,
    { listenToStorageChanges: false },
  );

  mergeNumberProperties(storageSelection.value, DEFAULT_SELECTION);

  const bbox = shallowRef(storageBbox.value);
  const zoom = ref(storageZoom.value);
  const selection = ref(storageSelection.value);

  watch([bbox, zoom, selection], ([newBbox, newZoom, newSelection]) => {
    storageBbox.value = newBbox;
    storageZoom.value = newZoom;
    storageSelection.value = newSelection;
  });

  return {
    bbox,
    zoom,
    selection,
  };
}
