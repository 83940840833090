import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { createCustomI18n } from "@/utils/i18n.util";

import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import { initSentry } from "@/utils/sentry.util";
import { initSupertokens } from "@/utils/api.util";
import { initStripe } from "@/utils/stripe.util";
import { plausible } from "@/utils/plausible.util";
import { MotionPlugin } from "@vueuse/motion";

import "@unocss/reset/tailwind.css";
import "virtual:uno.css";
import "@/assets/base.css";

// NaiveUI styles after preflight CSS reset
const meta = document.createElement("meta");
meta.name = "naive-ui-style";
document.head.appendChild(meta);

const app = createApp(App);

initSentry(app);
initSupertokens();
initStripe();

app
  .use(createPinia())
  .use(router)
  .use(createCustomI18n())
  .use(plausible)
  .use(MotionPlugin)
  .use(autoAnimatePlugin)
  .mount("#app");
