import {
  MIN_CONTOUR_STEP,
  MIN_TERRAIN_SPACING,
  type ExportSelection,
} from "./export-options.constant";

type OmitStrict<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type LayersConfig = Pick<
  ExportSelection,
  "satellite" | "modeling" | "vectorial"
> & {
  vectorialOptions: OmitStrict<
    ExportSelection["vectorialOptions"],
    | "geolocate"
    | "format"
    | "contourStep"
    | "shadowsAngle"
    | "shadowsBuildingsDefaultHeight"
  > & {
    minContourStep: number;
    minSuggestedContourStep: number;
  };
  modelingOptions: OmitStrict<
    ExportSelection["modelingOptions"],
    | "geolocate"
    | "format"
    | "contourStep"
    | "terrainSpacing"
    | "terrainOptimization"
    | "buildingsDefaultHeight"
  > & {
    minContourStep: number;
    minSuggestedContourStep: number;
    minTerrainSpacing: number;
    minSuggestedTerrainSpacing: number;
  };
};

export type DatasetType =
  | "BUILDINGS_2D"
  | "BUILDINGS_3D"
  | "BUILDINGS_3D_LOD2"
  | "RASTER_DTM"
  | "RASTER_DSM"
  | "IMAGERY"
  | "PARCELS"
  | "ROADS"
  | "ROADS_OUTLINE"
  | "TREES"
  | "WALLS"
  | "ROOFS"
  | "FLOORS"
  | "RAILWAYS"
  | "WATERWAYS"
  | "CANOPY"
  | "GREEN_AREAS";

export type ResourceType = "TABLE" | "WMS" | "CUSTOM" | "COMPUTED";

export type Dataset = {
  id: string;
  productId: string;
  type: DatasetType;
  resourceType: ResourceType;
  year: number;
  precision: string | null;
  priority: number | null;
  hasCoverageGeometry: boolean;
  regionIsoCode: string;
  parentDatasetIds: string[];
};

export type Product = {
  id: string;
  name: string;
  licence: string;
  publisherId: string;
};

export type Publisher = {
  id: string;
  name: string;
  shortName: string;
};

export type FullProduct = Product & { publisher: Publisher };

export type FullDataset = Dataset & {
  product: FullProduct;
  parents?: FullDataset[];
};

export type RegionWithRadius = Region & { radius: number };

export type Region = {
  isoCodes: string[];
  priceRatio: string;
  datasets: Dataset[];
};

export type CheckCoveringDto = { id: string; covers: boolean }[];

export const DEFAULT_LAYERS_CONFIG: LayersConfig = {
  satellite: true,
  vectorial: true,
  vectorialOptions: {
    buildings: true,
    parcels: true,
    roads: true,
    railways: true,
    waterways: true,
    shadows: true,
    trees: true,
    contours: true,
    minContourStep: MIN_CONTOUR_STEP,
    minSuggestedContourStep: MIN_CONTOUR_STEP,
  },
  modeling: true,
  modelingOptions: {
    buildings: true,
    parcels: true,
    roads: true,
    railways: true,
    waterways: true,
    trees: true,
    contours: true,
    minContourStep: MIN_CONTOUR_STEP,
    minSuggestedContourStep: MIN_CONTOUR_STEP,
    terrain: true,
    minTerrainSpacing: MIN_TERRAIN_SPACING,
    minSuggestedTerrainSpacing: MIN_TERRAIN_SPACING,
  },
};
