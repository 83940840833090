<template>
  <RouterView
    class="min-height-screen flex flex-col"
    :class="{ 'height-screen': heightScreen }" />
</template>

<script lang="ts" setup>
import { useDialog, useMessage } from "naive-ui";
import { api } from "./utils/api.util";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { setUser } from "@sentry/vue";
import { useAccessTokenPayload } from "@/composables/access-token-payload.composable";

window.$dialog = useDialog();
window.$message = useMessage();

const payload = await useAccessTokenPayload();
if (payload) {
  setUser({ email: payload.email });
  await api("/me");
}

const route = useRoute();
const breakpoints = useBreakpoints(breakpointsTailwind);

const heightScreen = computed(
  () =>
    route.meta.heightScreen &&
    (!route.meta.heightScreenFrom ||
      breakpoints.greaterOrEqual(route.meta.heightScreenFrom as any).value),
);
</script>

<style>
.min-height-screen {
  min-height: 100vh;
  min-height: 100dvh;
}

.height-screen {
  height: 100vh;
  height: 100dvh;
}
</style>
