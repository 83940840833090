import type { CurrencyCode } from "@/composables/geolocation.composable";
import type { Option } from "@/constants/option.type";
import type { SelectOption } from "naive-ui";
import type { Dataset, DatasetType, FullDataset } from "./regions.constant";
import Big from "big.js";

import PhImageBold from "~icons/ph/image-bold";
import PhCubeBold from "~icons/ph/cube-bold";
import MdiPrinter3dNozzle from "~icons/mdi/printer-3d-nozzle";
import SimpleIconsAutodesk from "~icons/simple-icons/autodesk";
import SimpleIconsAdobe from "~icons/simple-icons/adobe";
import SimpleIconsAdobeillustrator from "~icons/simple-icons/adobeillustrator";
import CustomIFCIcon from "@/components/CustomIFCIcon.vue";
import type { LngLatBounds } from "maplibre-gl";
import type { Rate } from "@/utils/pricing.util";

export const MAX_RATE_AREA: Record<Rate, number> = {
  basic: 1,
  pro: 10,
  proplus: 50,
};
export const MAX_AREA = MAX_RATE_AREA.proplus;

export const MIN_CONTOUR_STEP = 0.1;
export const MAX_CONTOUR_STEP = 100;
export const MIN_TERRAIN_SPACING = 0.5;
export const MAX_TERRAIN_SPACING = 50;
export const CONTOUR_STEP_PRO_BELOW = 5;
export const TERRAIN_SPACING_PRO_BELOW = 20;

export type SelectOptionPro = SelectOption & {
  pro?: boolean;
  warning?: boolean;
};

export const SATELLITE_FORMAT_OPTIONS = [
  {
    icon: PhImageBold,
    label: "JPEG",
    value: "JPEG",
  },
] as const satisfies Readonly<Option[]>;
export type SatelliteFormat =
  (typeof SATELLITE_FORMAT_OPTIONS)[number]["value"];

export const VECTO_FORMAT_OPTIONS = [
  {
    icon: SimpleIconsAutodesk,
    label: "DXF",
    value: "DXF",
  },
  {
    icon: SimpleIconsAdobe,
    label: "PDF",
    value: "PDF",
  },
  {
    icon: SimpleIconsAdobeillustrator,
    label: "SVG",
    value: "SVG",
  },
] as const satisfies Readonly<Option[]>;
export type VectoFormat = (typeof VECTO_FORMAT_OPTIONS)[number]["value"];

export const MODELING_FORMAT_OPTIONS = [
  {
    icon: SimpleIconsAutodesk,
    label: "DXF",
    value: "DXF",
  },
  {
    icon: CustomIFCIcon,
    label: "IFC",
    value: "IFC",
    rate: "proplus",
  },
  {
    icon: PhCubeBold,
    label: "OBJ",
    value: "OBJ",
  },
  {
    icon: MdiPrinter3dNozzle,
    label: "STL",
    value: "STL",
  },
] as const satisfies Readonly<Option[]>;
export type ModelingFormat = (typeof MODELING_FORMAT_OPTIONS)[number]["value"];

export type ExportSelection = {
  satellite: boolean;
  satelliteOptions: {
    format?: SatelliteFormat;
  };
  vectorial: boolean;
  vectorialOptions: {
    format?: VectoFormat;
    geolocate: boolean;
    buildings: boolean;
    parcels: boolean;
    roads: boolean;
    railways: boolean;
    waterways: boolean;
    trees: boolean;
    shadows: boolean;
    shadowsAngle?: number;
    shadowsBuildingsDefaultHeight?: number;
    contours: boolean;
    contourStep?: number;
  };
  modeling: boolean;
  modelingOptions: {
    format?: ModelingFormat;
    geolocate: boolean;
    buildings: boolean;
    buildingsDefaultHeight?: number;
    parcels: boolean;
    roads: boolean;
    railways: boolean;
    waterways: boolean;
    trees: boolean;
    contours: boolean;
    contourStep?: number;
    terrain: boolean;
    terrainSpacing?: number;
    terrainOptimization: boolean;
  };
};

export type ExportSelectionDto = (
  | {
      satellite: true;
      satelliteOptions: ExportSelection["satelliteOptions"] & {
        datasetIds: string[];
      };
    }
  | { satellite: false }
) &
  (
    | {
        vectorial: true;
        vectorialOptions: ExportSelection["vectorialOptions"] & {
          datasetIds: string[];
        };
      }
    | { vectorial: false }
  ) &
  (
    | {
        modeling: true;
        modelingOptions: ExportSelection["modelingOptions"] & {
          datasetIds: string[];
        };
      }
    | { modeling: false }
  );

export type ExportSelectionWithFullDatasetsDto = (
  | {
      satellite: true;
      satelliteOptions: ExportSelection["satelliteOptions"] & {
        datasets: FullDataset[];
      };
    }
  | { satellite: false }
) &
  (
    | {
        vectorial: true;
        vectorialOptions: ExportSelection["vectorialOptions"] & {
          datasets: FullDataset[];
        };
      }
    | { vectorial: false }
  ) &
  (
    | {
        modeling: true;
        modelingOptions: ExportSelection["modelingOptions"] & {
          datasets: FullDataset[];
        };
      }
    | { modeling: false }
  );

type LatitudeLongitude = { latitude: number; longitude: number };

type ComputeDetailsDto = {
  northEast: LatitudeLongitude;
  southWest: LatitudeLongitude;
  language: string;
  currencyCode: CurrencyCode;
};

export type ComputeDto = ComputeDetailsDto & ExportSelectionDto;

export type ComputeResponseDto = ComputeDetailsDto &
  ExportSelectionWithFullDatasetsDto & {
    exportJobId: string;
    preview: boolean;
  };

function addParents(dataset: Dataset, allDatasets: Dataset[]) {
  return {
    ...dataset,
    parents: dataset.parentDatasetIds
      .map((parentId) => allDatasets.find((d) => d.id === parentId))
      .filter(Boolean),
  };
}

function filterAndSortBestDatasetsOfType(
  datasets: Dataset[],
  type: DatasetType,
) {
  return datasets
    .filter((d) => d.type === type)
    .sort((a, b) => {
      // Sort by priority (descending)
      if (a.priority != null && b.priority == null) {
        return -1; // a comes before b
      }
      if (a.priority == null && b.priority != null) {
        return 1; // b comes before a
      }
      if (a.priority != null && b.priority != null) {
        if (a.priority !== b.priority) {
          return b.priority - a.priority; // Sort by priority descending
        }
      }

      // Sort by precision (ascending)
      if (a.precision != null && b.precision == null) {
        return -1; // a comes before b
      }
      if (a.precision == null && b.precision != null) {
        return 1; // b comes before a
      }
      if (a.precision != null && b.precision != null) {
        const aPrecision = Big(a.precision);
        const bPrecision = Big(b.precision);

        if (!aPrecision.eq(bPrecision)) {
          return aPrecision.minus(bPrecision).toNumber(); // Sort by precision ascending
        }
      }

      // Sort by regionIsoCode
      if (a.regionIsoCode === "WORLD" && b.regionIsoCode !== "WORLD") {
        return 1; // b comes before a
      }
      if (a.regionIsoCode !== "WORLD" && b.regionIsoCode === "WORLD") {
        return -1; // a comes before b
      }

      // If priority, precision, and regionIsoCode are equal or undefined, maintain original order
      return 0;
    });
}

export function selectBestDatasetOfType<Mandatory extends boolean>(
  datasets: Dataset[],
  type: DatasetType,
  throwIfNotFound?: Mandatory,
): Mandatory extends true ? Dataset : undefined {
  const filteredAndSorted = filterAndSortBestDatasetsOfType(datasets, type);

  if (filteredAndSorted.length === 0) {
    if (throwIfNotFound) {
      throw new Error(`No dataset of type ${type}`);
    }
    return undefined as Mandatory extends true ? Dataset : undefined;
  }

  return filteredAndSorted[0] as Mandatory extends true ? Dataset : undefined;
}

export function transformToComputeDto(
  bbox: LngLatBounds,
  selection: ExportSelection,
  locale: string,
  currencyCode: CurrencyCode,
  datasets: Dataset[],
) {
  // @ts-ignore
  const dto: ComputeDto = {
    northEast: {
      latitude: bbox.getNorthEast().lat,
      longitude: bbox.getNorthEast().lng,
    },
    southWest: {
      latitude: bbox.getSouthWest().lat,
      longitude: bbox.getSouthWest().lng,
    },
    language: locale,
    currencyCode,
    satellite: selection.satellite,
    vectorial: selection.vectorial,
    modeling: selection.modeling,
  };

  if (dto.satellite) {
    const satelliteDataset = selectBestDatasetOfType(datasets, "IMAGERY", true);
    dto.satelliteOptions = {
      ...selection.satelliteOptions,
      datasetIds: satelliteDataset ? [satelliteDataset.id] : [],
    };
  }

  if (dto.vectorial) {
    const vectorialDatasets: Dataset[] = [];

    if (selection.vectorialOptions.shadows) {
      const buildings3dDatasets = filterAndSortBestDatasetsOfType(
        datasets,
        "BUILDINGS_3D",
      );

      vectorialDatasets.push(
        // exception pour la France et la Suisse
        buildings3dDatasets.find((d) => d.resourceType === "COMPUTED") ??
          buildings3dDatasets[0] ??
          selectBestDatasetOfType(datasets, "BUILDINGS_2D", true),
      );
    }

    if (
      selection.vectorialOptions.buildings &&
      !vectorialDatasets.some((d) => d.type === "BUILDINGS_3D") &&
      !vectorialDatasets.some((d) => d.type === "BUILDINGS_2D")
    ) {
      vectorialDatasets.push(
        selectBestDatasetOfType(datasets, "BUILDINGS_2D") ??
          selectBestDatasetOfType(datasets, "BUILDINGS_3D", true),
      );
    }

    if (selection.vectorialOptions.parcels) {
      vectorialDatasets.push(
        selectBestDatasetOfType(datasets, "PARCELS", true),
      );
    }

    if (selection.vectorialOptions.roads) {
      vectorialDatasets.push(selectBestDatasetOfType(datasets, "ROADS", true));

      const roadsOutline = selectBestDatasetOfType(datasets, "ROADS_OUTLINE");
      if (roadsOutline) {
        vectorialDatasets.push(roadsOutline);
      }
    }

    if (selection.vectorialOptions.railways) {
      vectorialDatasets.push(
        selectBestDatasetOfType(datasets, "RAILWAYS", true),
      );
    }

    if (selection.vectorialOptions.waterways) {
      const waterways = filterAndSortBestDatasetsOfType(datasets, "WATERWAYS");
      if (waterways.some((v) => v.regionIsoCode !== "WORLD")) {
        vectorialDatasets.push(
          ...waterways.filter((w) => w.regionIsoCode !== "WORLD"),
        );
      } else {
        vectorialDatasets.push(
          waterways.find((w) => w.regionIsoCode === "WORLD")!,
        );
      }

    }

    if (selection.vectorialOptions.trees) {
      vectorialDatasets.push(selectBestDatasetOfType(datasets, "TREES", true));

      const canopy = selectBestDatasetOfType(datasets, "CANOPY");
      if (canopy) {
        vectorialDatasets.push(canopy);
      }

      const greenAreas = selectBestDatasetOfType(datasets, "GREEN_AREAS");
      if (greenAreas) {
        vectorialDatasets.push(greenAreas);
      }
    }

    if (selection.vectorialOptions.contours) {
      vectorialDatasets.push(
        selectBestDatasetOfType(datasets, "RASTER_DTM", true),
      );
    }

    dto.vectorialOptions = {
      ...selection.vectorialOptions,
      shadowsAngle: selection.vectorialOptions.shadows
        ? selection.vectorialOptions.shadowsAngle
        : undefined,
      shadowsBuildingsDefaultHeight: selection.vectorialOptions.shadows
        ? selection.vectorialOptions.shadowsBuildingsDefaultHeight
        : undefined,
      contourStep: selection.vectorialOptions.contours
        ? selection.vectorialOptions.contourStep
        : undefined,
      datasetIds: vectorialDatasets.map((d) => d.id),
    };
  }

  if (dto.modeling) {
    const modelingDatasets = [
      // we always need the raster for modeling (to put buildings and other assets on the ground level)
      selectBestDatasetOfType(datasets, "RASTER_DTM", true),
    ];

    if (selection.modelingOptions.buildings) {
      const lod2Datasets = filterAndSortBestDatasetsOfType(
        datasets,
        "BUILDINGS_3D_LOD2",
      );

      let lod2CompatibleWithFormat: Dataset | undefined;

      if (lod2Datasets.length) {
        const lod2DatasetsWithParents = lod2Datasets.map((d) =>
          addParents(d, datasets),
        );

        lod2CompatibleWithFormat =
          selection.modelingOptions.format === "IFC"
            ? lod2DatasetsWithParents.find((d) =>
                d.parents.some((p) => p?.type === "WALLS"),
              )
            : lod2DatasetsWithParents.find((d) =>
                d.parents.every((p) => p?.type !== "WALLS"),
              );
      }

      modelingDatasets.push(
        lod2CompatibleWithFormat ??
          selectBestDatasetOfType(datasets, "BUILDINGS_3D") ??
          selectBestDatasetOfType(datasets, "BUILDINGS_2D", true),
      );
    }

    if (selection.modelingOptions.parcels) {
      modelingDatasets.push(selectBestDatasetOfType(datasets, "PARCELS", true));
    }

    if (selection.modelingOptions.roads) {
      modelingDatasets.push(selectBestDatasetOfType(datasets, "ROADS", true));

      const roadsOutline = selectBestDatasetOfType(datasets, "ROADS_OUTLINE");

      if (roadsOutline) {
        modelingDatasets.push(roadsOutline);
      }
    }

    if (selection.modelingOptions.railways) {
      modelingDatasets.push(
        selectBestDatasetOfType(datasets, "RAILWAYS", true),
      );
    }

    if (selection.modelingOptions.waterways) {
      const waterways = filterAndSortBestDatasetsOfType(datasets, "WATERWAYS");
      if (waterways.some((v) => v.regionIsoCode !== "WORLD")) {
        modelingDatasets.push(
          ...waterways.filter((w) => w.regionIsoCode !== "WORLD"),
        );
      } else {
        modelingDatasets.push(
          waterways.find((w) => w.regionIsoCode === "WORLD")!,
        );
      }
    }

    if (selection.modelingOptions.trees) {
      modelingDatasets.push(selectBestDatasetOfType(datasets, "TREES", true));

      if (selection.modelingOptions.format !== "OBJ") {
        const canopy = selectBestDatasetOfType(datasets, "CANOPY");
        if (canopy) {
          modelingDatasets.push(canopy);
        }

        const greenAreas = selectBestDatasetOfType(datasets, "GREEN_AREAS");
        if (greenAreas) {
          modelingDatasets.push(greenAreas);
        }
      }
    }

    dto.modelingOptions = {
      ...selection.modelingOptions,
      buildingsDefaultHeight: selection.modelingOptions.buildings
        ? selection.modelingOptions.buildingsDefaultHeight
        : undefined,
      contourStep: selection.modelingOptions.contours
        ? selection.modelingOptions.contourStep
        : undefined,
      terrainSpacing: selection.modelingOptions.terrain
        ? selection.modelingOptions.terrainSpacing
        : undefined,
      datasetIds: modelingDatasets.map((d) => d.id),
    };
  }

  return dto;
}

export function transformToSelection(dto: ComputeDto): ExportSelection {
  return {
    satellite: dto.satellite,
    satelliteOptions: dto.satellite
      ? dto.satelliteOptions
      : {
          format: undefined,
        },
    vectorial: dto.vectorial,
    vectorialOptions: dto.vectorial
      ? dto.vectorialOptions
      : {
          format: undefined,
          geolocate: false,
          buildings: false,
          parcels: false,
          roads: false,
          railways: false,
          waterways: false,
          trees: false,
          shadows: false,
          shadowsAngle: undefined,
          shadowsBuildingsDefaultHeight: undefined,
          contours: false,
          contourStep: undefined,
        },
    modeling: dto.modeling,
    modelingOptions: dto.modeling
      ? dto.modelingOptions
      : {
          format: undefined,
          geolocate: false,
          buildings: false,
          buildingsDefaultHeight: undefined,
          parcels: false,
          roads: false,
          railways: false,
          waterways: false,
          trees: false,
          terrain: false,
          terrainSpacing: undefined,
          terrainOptimization: false,
          contours: false,
          contourStep: undefined,
        },
  };
}

export function getDefaultExportSelection(): ExportSelection {
  return {
    satellite: false,
    satelliteOptions: {
      format: undefined,
    },
    vectorial: true,
    vectorialOptions: {
      format: "DXF",
      geolocate: false,
      buildings: true,
      parcels: true,
      roads: true,
      railways: true,
      waterways: true,
      trees: false,
      shadows: false,
      shadowsAngle: 45,
      shadowsBuildingsDefaultHeight: 6,
      contours: true,
      contourStep: 5,
    },
    modeling: true,
    modelingOptions: {
      format: undefined,
      geolocate: false,
      buildings: true,
      buildingsDefaultHeight: 6,
      parcels: true,
      roads: true,
      railways: true,
      waterways: true,
      trees: false,
      terrain: true,
      terrainSpacing: 20,
      terrainOptimization: false,
      contours: true,
      contourStep: 5,
    },
  };
}

const LAYERS = [
  "imagery",
  "buildings",
  "parcels",
  "roads",
  "railways",
  "waterways",
  "trees",
  "canopy",
  "greenAreas",
  "shadows",
  "contours",
  "terrain",
] as const;

export type Layer = (typeof LAYERS)[number];

export type LayerOptions = Partial<
  {
    imagery: boolean;
  } & Omit<ExportSelection["vectorialOptions"], "format"> &
    Omit<ExportSelection["modelingOptions"], "format">
>;

export function optionsToLayers(options: Record<string, any>) {
  const layers = LAYERS.filter((layer) => options[layer]);

  if (options.trees) {
    layers.push("canopy", "greenAreas");
  }

  return layers;
}
