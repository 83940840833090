<template>
  <svg
    width="1.2em"
    height="1.2em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.833 2.01062C19.7062 2.02213 19.584 2.04285 19.5633 2.05436C19.5402 2.06817 19.5033 2.07738 19.4826 2.07738C19.4434 2.07738 19.1806 2.16485 19.0607 2.2178C18.9039 2.28916 18.6987 2.40656 18.5465 2.51706C18.3828 2.63446 15.9181 5.08378 15.9296 5.11601C15.9388 5.14364 18.2122 7.41801 18.2306 7.41801C18.2375 7.41801 18.6618 6.99904 19.1759 6.4857C19.6901 5.97235 20.1259 5.55339 20.1443 5.55339C20.1628 5.55339 21.5069 6.87934 23.1301 8.49995L26.0813 11.4465L22.4961 15.0261L18.9108 18.6057L19.1529 18.8497C19.5817 19.2825 19.9737 19.5518 20.3403 19.6646C20.391 19.6807 20.4533 19.7015 20.4786 19.7107C20.617 19.7613 20.6977 19.7797 20.7737 19.7797C20.8199 19.7797 20.8614 19.7889 20.8683 19.8004C20.8844 19.8235 21.5784 19.8166 21.7352 19.7935C21.7928 19.7843 21.8597 19.7682 21.8851 19.7567C21.9104 19.7475 22.0257 19.7107 22.1387 19.6738C22.3739 19.5979 22.5791 19.4966 22.7958 19.3469C22.8926 19.2802 24.0385 18.1545 26.0006 16.1978C29.2377 12.9658 29.1755 13.0326 29.3738 12.6205C29.5536 12.2453 29.6205 11.9207 29.6205 11.4465C29.6205 11.1334 29.5674 10.7421 29.5213 10.696C29.5121 10.6868 29.5052 10.6546 29.5052 10.627C29.5052 10.5971 29.496 10.5671 29.4821 10.5602C29.4706 10.5533 29.4591 10.5303 29.4591 10.5119C29.4591 10.4589 29.2954 10.1367 29.1686 9.9456C29.0832 9.81438 28.2901 9.01099 25.5072 6.22557C23.552 4.27118 21.892 2.62756 21.8159 2.57001C21.7398 2.51246 21.6614 2.45491 21.6407 2.43879C21.5415 2.36283 21.1818 2.18787 21.0435 2.14644C20.9859 2.13032 20.9305 2.1073 20.919 2.09579C20.9052 2.08659 20.8683 2.07738 20.8337 2.07738C20.7991 2.07738 20.7645 2.06817 20.7576 2.05666C20.7299 2.01292 20.1489 1.983 19.833 2.01062Z"
      fill="currentColor" />
    <path
      d="M11.0303 2.41599C11.0211 2.4229 10.9704 2.43901 10.9173 2.44822C10.8343 2.46203 10.7098 2.49426 10.6107 2.52419C10.4677 2.56792 10.1726 2.71755 9.97664 2.84416C9.8383 2.93624 9.08666 3.67288 6.23459 6.51815C4.27019 8.47484 2.61014 10.1507 2.54097 10.2405C2.28274 10.5812 2.11443 10.9863 2.02451 11.4836C1.99223 11.6539 1.99223 12.0798 2.0222 12.211C2.03373 12.2662 2.05678 12.3721 2.07062 12.4481C2.13518 12.7681 2.31963 13.1686 2.53405 13.461C2.65625 13.6267 5.07947 16.0737 5.12328 16.0737C5.13481 16.0737 5.6651 15.5558 6.29915 14.9227L7.45197 13.7717L6.49974 12.821C5.97867 12.3008 5.55213 11.8611 5.55443 11.8496C5.55674 11.8358 6.88939 10.5006 8.51717 8.87999L11.4753 5.93344L15.0583 9.51074L18.6435 13.0903L18.8695 12.8601C19.3975 12.3261 19.6119 11.9969 19.7456 11.5158C19.8217 11.2418 19.8286 11.1682 19.8286 10.7446C19.8286 10.3187 19.8217 10.2497 19.7433 9.96423C19.681 9.73864 19.5266 9.42327 19.3652 9.19076C19.2222 8.98589 13.2483 3.02142 13.0431 2.87869C12.9186 2.79352 12.7296 2.68763 12.5128 2.58404C12.4944 2.57483 12.416 2.54951 12.3399 2.52879C12.2638 2.50577 12.1831 2.47815 12.1624 2.46664C12.1393 2.45513 12.0863 2.44592 12.0425 2.44592C11.9987 2.44592 11.9549 2.43441 11.948 2.4229C11.9318 2.39758 11.0557 2.39067 11.0303 2.41599Z"
      fill="currentColor" />
    <path
      d="M12.667 8.34569C12.2681 8.75774 12.109 8.98794 11.9615 9.37007C11.8231 9.72918 11.7909 9.91334 11.7909 10.3415C11.7886 10.7029 11.8116 10.9538 11.8462 10.9769C11.8577 10.9838 11.867 11.0114 11.867 11.039C11.8693 11.2117 12.109 11.7043 12.3511 12.0266C12.3949 12.0864 13.2388 12.9405 14.2233 13.9257L16.0171 15.7167L17.1699 14.5657C17.804 13.9326 18.3227 13.4055 18.3227 13.3917C18.3227 13.3686 13.0129 8.05563 12.9783 8.04643C12.969 8.04412 12.8307 8.17764 12.667 8.34569Z"
      fill="currentColor" />
    <path
      d="M21.274 11.7919C21.2417 11.8011 21.1772 11.8103 21.1311 11.8126C21.0849 11.8149 21.0388 11.8241 21.0319 11.8379C21.025 11.8494 20.9927 11.8609 20.9604 11.8609C20.9305 11.8609 20.8867 11.8701 20.8636 11.8816C20.8429 11.8931 20.7829 11.9139 20.7322 11.93C20.5708 11.9806 20.3218 12.1072 20.1373 12.2338C20.0036 12.3259 19.4733 12.8416 18.136 14.1767L16.3169 15.993L17.4674 17.1417L18.6156 18.2881L21.2855 15.6247L23.9532 12.9613L23.6926 12.6965C23.2107 12.2131 22.8603 12.0013 22.277 11.8425C22.0256 11.7734 21.4262 11.7435 21.274 11.7919Z"
      fill="currentColor" />
    <path
      d="M10.5506 12.1463C10.5459 12.1509 10.4791 12.1624 10.403 12.1693C10.237 12.1854 10.0226 12.2315 9.91881 12.2752C9.89345 12.2867 9.82197 12.312 9.75742 12.3351C9.56144 12.4041 9.34471 12.5284 9.11645 12.7011C9.03345 12.7632 7.6224 14.1582 5.98079 15.7973C3.34084 18.4354 2.98116 18.8037 2.85665 18.9924C2.56153 19.4367 2.43011 19.8327 2.40014 20.3598C2.38169 20.6821 2.44394 21.2093 2.51081 21.2852C2.52003 21.299 2.52925 21.3313 2.52925 21.3589C2.52925 21.3888 2.54078 21.4187 2.55231 21.4256C2.56614 21.4325 2.57536 21.4533 2.57536 21.4717C2.57536 21.5269 2.70679 21.7894 2.82898 21.9781C2.93043 22.1324 3.52529 22.7378 6.57103 25.7833C10.3407 29.5494 10.2808 29.4918 10.6566 29.6829C10.818 29.7658 10.9356 29.8164 10.9656 29.8164C10.9794 29.8164 11.0094 29.8256 11.0324 29.8371C11.0532 29.8486 11.1177 29.8694 11.1754 29.8855C11.233 29.8993 11.3114 29.9223 11.3506 29.9338C11.3898 29.9453 11.4843 29.9545 11.5627 29.9545C11.6388 29.9545 11.7103 29.966 11.7172 29.9776C11.7356 30.0075 12.0215 30.0075 12.04 29.9776C12.0469 29.9637 12.1253 29.9545 12.2129 29.9545C12.3005 29.9545 12.3789 29.943 12.3858 29.9315C12.3928 29.9177 12.425 29.9085 12.4573 29.9085C12.5334 29.9085 12.8262 29.8095 13.0176 29.7197C13.4257 29.5287 13.4718 29.4895 14.8344 28.1314L16.121 26.8468L14.9681 25.6958C14.3341 25.0628 13.8061 24.5448 13.7923 24.5448C13.7807 24.5448 13.3404 24.973 12.8193 25.4933L11.8671 26.444L8.91586 23.4974C7.29269 21.8768 5.96465 20.5417 5.96465 20.5279C5.96465 20.5164 7.57167 18.9004 9.53838 16.9368L13.1121 13.3687L12.817 13.074C12.6556 12.9106 12.4435 12.7218 12.3443 12.6504C12.1299 12.4985 11.821 12.3397 11.6388 12.2867C11.5673 12.2637 11.4889 12.2384 11.4636 12.2292C11.3322 12.1739 10.5897 12.1072 10.5506 12.1463Z"
      fill="currentColor" />
    <path
      d="M10.7191 16.3337C9.25961 17.7909 8.06299 18.9971 8.06299 19.0109C8.06299 19.0455 8.52873 19.4944 8.72009 19.6463C8.81001 19.7153 8.9276 19.7982 8.98524 19.8304C9.04288 19.8627 9.12589 19.9087 9.16969 19.934C9.2135 19.9594 9.30803 19.9985 9.3772 20.0215C9.44637 20.0468 9.51323 20.0745 9.52707 20.0837C9.53859 20.0929 9.57087 20.1021 9.59624 20.1021C9.6239 20.1021 9.65388 20.109 9.6631 20.1182C9.67002 20.1274 9.69538 20.1366 9.71843 20.1389C9.73918 20.1435 9.83602 20.1596 9.93055 20.1757C10.1542 20.2126 10.6061 20.2126 10.8413 20.1757C11.1664 20.1228 11.4869 19.9985 11.8212 19.7936C11.9042 19.743 12.0494 19.6348 12.144 19.5519C12.3584 19.3631 15.7177 16.0137 15.7177 15.9884C15.7177 15.9608 13.4282 13.6795 13.4005 13.6795C13.3867 13.6795 12.1809 14.8742 10.7191 16.3337Z"
      fill="currentColor" />
    <path
      d="M25.7238 17.0521L24.5825 18.1916L25.5347 19.1423C26.0558 19.6625 26.4824 20.1068 26.4801 20.1252C26.4778 20.1437 25.1451 21.4857 23.5173 23.1063L20.5592 26.0529L16.9785 22.4779L13.3979 18.9029L13.0636 19.2436C12.8814 19.43 12.6831 19.6556 12.6232 19.7454C12.4572 19.9963 12.2843 20.3877 12.282 20.5074C12.282 20.5327 12.275 20.5626 12.2658 20.5718C12.2013 20.6363 12.1644 21.4259 12.2151 21.6722C12.3096 22.1418 12.4872 22.54 12.7592 22.8922C12.7823 22.9222 14.1334 24.2803 15.7635 25.9102C17.7025 27.8507 18.7931 28.9281 18.9199 29.0225C19.1066 29.1629 19.547 29.4023 19.6231 29.4023C19.6415 29.4023 19.6646 29.4138 19.6715 29.4253C19.6784 29.4391 19.7061 29.4483 19.7315 29.4483C19.7545 29.4483 19.7937 29.4575 19.8168 29.469C19.8375 29.4806 19.9574 29.5082 20.0819 29.5312C20.4185 29.5887 21.1817 29.5473 21.2647 29.4644C21.2739 29.4552 21.3039 29.4483 21.3316 29.4483C21.3569 29.4483 21.3892 29.4391 21.403 29.4299C21.4146 29.4207 21.4814 29.3931 21.5506 29.3678C21.6198 29.3447 21.7235 29.2987 21.7812 29.2688C21.8388 29.2365 21.9057 29.1997 21.931 29.1859C21.9564 29.1721 21.9818 29.1537 21.9887 29.1468C21.9956 29.1399 22.0671 29.0869 22.1501 29.0271C22.24 28.9649 23.7456 27.4778 25.8645 25.3577C28.6635 22.5608 29.4543 21.7574 29.5465 21.6192C29.6572 21.4535 29.8509 21.0737 29.8509 21.023C29.8509 21.0092 29.8601 20.9816 29.8716 20.9586C30.0169 20.68 30.0469 19.7362 29.9201 19.4231C29.9108 19.3978 29.8832 19.3172 29.8624 19.2436C29.8094 19.064 29.7102 18.8637 29.5581 18.6289C29.452 18.4655 29.2168 18.2192 28.1724 17.1718C27.4784 16.4789 26.902 15.9126 26.8882 15.9126C26.8766 15.9126 26.3509 16.4259 25.7238 17.0521Z"
      fill="currentColor" />
    <path
      d="M14.8464 17.4388L13.689 18.5944L16.3635 21.2647L19.0403 23.9351L19.3677 23.6013C19.7044 23.2606 19.8104 23.1225 19.958 22.8324C20.1678 22.4296 20.237 22.128 20.2347 21.61C20.2347 21.1243 20.1793 20.8665 19.9926 20.4544C19.822 20.0792 19.7735 20.0263 17.8529 18.1041C16.85 17.1027 16.0246 16.2809 16.0177 16.2809C16.0107 16.2809 15.4851 16.8012 14.8464 17.4388Z"
      fill="currentColor" />
  </svg>
</template>
