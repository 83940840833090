import { convertArea, getAreaOfPolygon } from "geolib";
import type { LngLatBounds } from "maplibre-gl";
import type { MaybeRefOrGetter } from "vue";

export function useArea(bbox: MaybeRefOrGetter<LngLatBounds | undefined>) {
  return computed(() => {
    const bboxValue = toValue(bbox);

    if (!bboxValue) return 0;

    const polygon = [
      bboxValue.getNorthWest(),
      bboxValue.getNorthEast(),
      bboxValue.getSouthEast(),
      bboxValue.getSouthWest(),
    ];

    const areaSqm = getAreaOfPolygon(polygon);
    const areaSqKm = convertArea(areaSqm, "km2");

    return areaSqKm;
  });
}