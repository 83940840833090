import {
  MAX_AREA,
  type ExportSelection,
} from "@/constants/export-options.constant";
import { getPricing, type Pricing } from "@/utils/pricing.util";
import Big from "big.js";
import type { MaybeRefOrGetter } from "vue";
import type { UserBalances } from "./balances.composable";

export function usePricing(
  areaRef: MaybeRefOrGetter<number>,
  priceRatioRef: MaybeRefOrGetter<Big | undefined>,
  selectionRef: MaybeRefOrGetter<ExportSelection>,
  balancesRef: MaybeRefOrGetter<UserBalances | undefined>,
) {
  return computed((): Pricing | undefined => {
    const area = toValue(areaRef);
    const priceRatio = toValue(priceRatioRef);
    const selection = toValue(selectionRef);
    const balances = toValue(balancesRef);

    if (
      !priceRatio ||
      !balances ||
      area > MAX_AREA ||
      (!selection.satellite && !selection.vectorial && !selection.modeling)
    ) {
      return undefined;
    }

    return getPricing(area, priceRatio, selection, balances);
  });
}
