import { createPlausible } from "@huntersofbook/plausible-vue";

export const plausible = createPlausible({
  init: {
    domain: import.meta.env.VITE_APP_BASE_URL.replace(/^https?:\/\//, ""),
    apiHost: import.meta.env.VITE_PLAUSIBLE_API_HOST,
    trackLocalhost: false,
  },
  settings: {
    enableAutoOutboundTracking: true,
    enableAutoPageviews: true,
  },
  partytown: false,
});
