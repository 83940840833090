import { ofetch } from "ofetch";
import { init as supertokensInit } from "supertokens-web-js";
import {
  getAccessTokenPayloadSecurely,
  init as sessionInit,
} from "supertokens-web-js/recipe/session";
import { init as passwordlessInit } from "supertokens-web-js/recipe/passwordless";
import { init as thirdpartyInit } from "supertokens-web-js/recipe/thirdparty";
import type { AccessTokenPayload } from "@/dtos/access-token-payload.dto";
import { setUser, getIsolationScope } from "@sentry/vue";

export const api = ofetch.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  credentials: "include",
  onRequestError: ({ error }) => {
    window.$message.error(error.message);
  },
  onResponseError: ({ request, response, options }) => {
    const message = response._data.message;

    const messageType = response.status >= 500 ? "error" : "warning";
    window.$message.create(
      message ??
        (response.status === 429
          ? "Too many requests. Please wait and retry later."
          : "Error"),
      { type: messageType },
    );

    const lastBreadcrumb = getIsolationScope().getLastBreadcrumb();

    if (
      lastBreadcrumb &&
      lastBreadcrumb.category === "fetch" &&
      lastBreadcrumb.data &&
      lastBreadcrumb.data.method === options.method &&
      lastBreadcrumb.data.url === request &&
      lastBreadcrumb.data.status_code === response.status
    ) {
      lastBreadcrumb.data.payload = options.body;
      lastBreadcrumb.data.responseBody = response._data;
    }
  },
});

export function initSupertokens() {
  supertokensInit({
    appInfo: {
      apiDomain: import.meta.env.VITE_SUPERTOKENS_API_DOMAIN,
      apiBasePath: import.meta.env.VITE_SUPERTOKENS_API_BASE_PATH,
      appName: import.meta.env.VITE_SUPERTOKENS_APP_NAME,
    },
    recipeList: [
      sessionInit({
        onHandleEvent: async (event) => {
          if (event.action === "SESSION_CREATED") {
            const payload =
              (await getAccessTokenPayloadSecurely()) as AccessTokenPayload;
            setUser({ email: payload?.email });
          } else if (event.action === "SIGN_OUT") {
            setUser(null);
          }
        },
      }),
      passwordlessInit(),
      thirdpartyInit(),
    ],
  });
}
