import { type MaybeRefOrGetter, ref, type Ref } from "vue";
import {
  type CheckCoveringDto,
  type Dataset,
  DEFAULT_LAYERS_CONFIG,
  type LayersConfig,
  type RegionWithRadius,
} from "@/constants/regions.constant";
import { api } from "@/utils/api.util";
import {
  cartesianDistance,
  epsg4326to3857,
  type Point,
} from "@/utils/geo.util";
import { doesSessionExist } from "supertokens-web-js/recipe/session";
import type { LngLatBounds } from "maplibre-gl";
import { computedAsync, useDebounceFn } from "@vueuse/core";
import {
  MIN_CONTOUR_STEP,
  MIN_TERRAIN_SPACING,
} from "@/constants/export-options.constant";

export function useRegion(bbox: Ref<LngLatBounds | undefined>) {
  const _ref = ref<RegionWithRadius>();

  const lastCenter3857 = ref<Point>();

  watch(
    bbox,
    async () => {
      const isLogged = await doesSessionExist();
      if (!isLogged) return;

      if (!bbox.value) return;

      const center = bbox.value.getCenter();
      const center3857 = epsg4326to3857(center);

      if (
        !lastCenter3857.value ||
        !_ref.value?.radius ||
        cartesianDistance(lastCenter3857.value, center3857) >=
          _ref.value?.radius
      ) {
        try {
          const data = await api<RegionWithRadius>("/regions/whereami", {
            query: center3857,
          });

          lastCenter3857.value = center3857;

          _ref.value = data;
        } catch {
          _ref.value = undefined;
        }
      }
    },
    { immediate: true },
  );

  return _ref;
}

export function useAvailableDatasets(
  bboxRef: Ref<LngLatBounds | undefined>,
  datasetsRef: MaybeRefOrGetter<Dataset[] | undefined>,
) {
  const checkCovering = useDebounceFn(
    async (point: Point, datasetIds: string[]) => {
      return await api<CheckCoveringDto>("/datasets/check-covering", {
        query: {
          "datasetIds[]": datasetIds,
          "point[x]": point.x,
          "point[y]": point.y,
        },
      });
    },
    1000,
  );

  return computedAsync(async (): Promise<Dataset[] | undefined> => {
    const bbox = toValue(bboxRef);
    const datasets = toValue(datasetsRef);

    if (!datasets) return;

    if (bbox && datasets.some((d) => d.hasCoverageGeometry)) {
      const point = epsg4326to3857(bbox.getCenter());
      const limitedDatasetIds = datasets
        .filter((d) => d.hasCoverageGeometry)
        .map((d) => d.id);

      const data = await checkCovering(point, limitedDatasetIds);

      return datasets.filter(
        (a) => !data.some((d) => d.id === a.id && !d.covers),
      );
    } else {
      return datasets;
    }
  }, toValue(datasetsRef));
}

export function useLayers(
  availableDatasetsRef: MaybeRefOrGetter<Dataset[] | undefined>,
) {
  const _ref = ref<LayersConfig>(DEFAULT_LAYERS_CONFIG);

  watch(
    () => toValue(availableDatasetsRef),
    async (availableDatasets) => {
      if (!availableDatasets) return;

      const datasetTypes = [...new Set(availableDatasets.map((d) => d.type))];

      const dtmPrecisions = availableDatasets
        .filter((d) => d.type === "RASTER_DTM" && d.precision !== null)
        .map((d) => Number(d.precision!));
      const minPrecision = Math.min(...dtmPrecisions); // returns Infinity if dtmPrecisions is empty

      _ref.value = {
        satellite: datasetTypes.includes("IMAGERY"),
        vectorial: true,
        vectorialOptions: {
          buildings:
            datasetTypes.includes("BUILDINGS_2D") ||
            datasetTypes.includes("BUILDINGS_3D"),
          parcels: datasetTypes.includes("PARCELS"),
          roads: datasetTypes.includes("ROADS"),
          railways: datasetTypes.includes("RAILWAYS"),
          waterways: datasetTypes.includes("WATERWAYS"),
          trees: datasetTypes.includes("TREES"),
          shadows:
            datasetTypes.includes("BUILDINGS_2D") ||
            datasetTypes.includes("BUILDINGS_3D"),
          contours: datasetTypes.includes("RASTER_DTM"),
          minContourStep:
            minPrecision && minPrecision <= 1 ? MIN_CONTOUR_STEP : 1,
          minSuggestedContourStep:
            minPrecision && minPrecision <= 1 ? MIN_CONTOUR_STEP : 1,
        },
        modeling: true,
        modelingOptions: {
          buildings:
            datasetTypes.includes("BUILDINGS_2D") ||
            datasetTypes.includes("BUILDINGS_3D") ||
            datasetTypes.includes("BUILDINGS_3D_LOD2"),
          parcels: datasetTypes.includes("PARCELS"),
          roads: datasetTypes.includes("ROADS"),
          railways: datasetTypes.includes("RAILWAYS"),
          waterways: datasetTypes.includes("WATERWAYS"),
          trees: datasetTypes.includes("TREES"),
          contours: datasetTypes.includes("RASTER_DTM"),
          minContourStep:
            minPrecision && minPrecision <= 1 ? MIN_CONTOUR_STEP : 1,
          minSuggestedContourStep:
            minPrecision && minPrecision <= 1 ? MIN_CONTOUR_STEP : 1,
          terrain: datasetTypes.includes("RASTER_DTM"),
          minTerrainSpacing: minPrecision < 1 ? MIN_TERRAIN_SPACING : 1,
          minSuggestedTerrainSpacing:
            minPrecision < 1 ? MIN_TERRAIN_SPACING : 1,
        },
      };
    },
    { immediate: true },
  );

  return _ref;
}
