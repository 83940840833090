import type { Appearance } from "@stripe/stripe-js";
import type { GlobalThemeOverrides } from "naive-ui";

// keep default small size variants (mini, tiny, small) because we don't use them

export const THEME_OVERRIDES: GlobalThemeOverrides = {
  common: {
    primaryColor: "#2563eb", // blue-600
    primaryColorHover: "#3b82f6", // blue-500
    primaryColorSuppl: "#3b82f6", // blue-500
    primaryColorPressed: "#1d4ed8", // blue-700

    fontWeightStrong: "600",

    fontSize: "16px",
    fontSizeMedium: "16px",
    fontSizeLarge: "18px",
    fontSizeHuge: "22px",

    heightMedium: "44px",
    heightLarge: "48px",
    heightHuge: "52px",

    borderRadius: "8px",
  },
  Alert: {
    padding: "14px",
    iconMargin: "12px 8px 0 12px",
  },
  Button: {
    paddingMedium: "0 18px",
    paddingLarge: "0 20px",

    // disable borders on typed buttons (to have a smooth border-radius)
    // borderPrimary: "unset",
    // borderHoverPrimary: "unset",
    // borderPressedPrimary: "unset",
    // borderFocusPrimary: "unset",
    // borderDisabledPrimary: "unset",
    // borderInfo: "unset",
    // borderHoverInfo: "unset",
    // borderPressedInfo: "unset",
    // borderFocusInfo: "unset",
    // borderDisabledInfo: "unset",
    // borderSuccess: "unset",
    // borderHoverSuccess: "unset",
    // borderPressedSuccess: "unset",
    // borderFocusSuccess: "unset",
    // borderDisabledSuccess: "unset",
    // borderWarning: "unset",
    // borderHoverWarning: "unset",
    // borderPressedWarning: "unset",
    // borderFocusWarning: "unset",
    // borderDisabledWarning: "unset",
    // borderError: "unset",
    // borderHoverError: "unset",
    // borderPressedError: "unset",
    // borderFocusError: "unset",
    // borderDisabledError: "unset",

    iconMarginMedium: "0 12px 0 0",
  },
  Card: {
    borderRadius: "16px",
    paddingMedium: "18px",
  },
  Checkbox: {
    sizeMedium: "18px",
    sizeLarge: "20px",
    borderRadius: "4px",
  },
  Form: {
    labelFontSizeTopMedium: "14px",
    labelFontSizeTopLarge: "16px",
    labelFontSizeLeftMedium: "14px",
    labelFontSizeLeftLarge: "16px",
    feedbackFontSizeMedium: "14px",
    feedbackFontSizeLarge: "16px",

    // labelPaddingVertical: "0 0 8px 4px",
    // labelPaddingHorizontal: "0 14px 0 0",
  },
  Input: {
    paddingMedium: "0 16px",
    paddingLarge: "0 18px",
  },
  Message: {
    fontSize: "16px",
    iconSize: "24px",
  },
  Radio: {
    labelPadding: "0 12px",
  },
  Result: {
    fontSizeSmall: "14px",
    fontSizeMedium: "16px",
    fontSizeLarge: "18px",
    fontSizeHuge: "22px",
  },
  Steps: {
    stepHeaderFontSizeMedium: "18px",
    indicatorIndexFontSizeMedium: "20px",
    indicatorSizeMedium: "34px",
    indicatorIconSizeMedium: "20px",
  },
  Tag: {
    borderRadius: "6px",
    closeBorderRadius: "4px",
  },
};

export const STRIPE_APPEARANCE: Appearance = {
  theme: "stripe",
  variables: {
    fontFamily: "Inter, sans-serif",
    fontSizeBase: "16px",
    fontSizeSm: "14px",
    borderRadius: "8px",
    colorPrimary: "#2563eb", // blue-600
    colorTextPlaceholder: "#c2c2c2",
    focusBoxShadow: "0 0 0 2px #2563eb33",
  },
};
