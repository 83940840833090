export enum Routes {
  SPACE = "space",
  SPACE_MAP = "space.map",
  SPACE_EXPORT = "space.export",
  SPACE_HISTORY = "space.history",
  SPACE_PLANS = "space.plans",

  SPACE_ABOUT = "space.about",

  AUTH = "auth",
  AUTH_LOGIN = "auth.login",
  AUTH_CHECK_EMAIL = "auth.check-email",
  AUTH_VERIFY = "auth.verify",
  AUTH_GOOGLE_CALLBACK = "auth.google-callback",
}
