import type { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

export let stripe: Stripe;

export const initStripe = async () => {
  const instance = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
  if (instance === null) {
    throw new Error("Can't load Stripe");
  }
  stripe = instance;
};
