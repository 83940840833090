import type { AccessTokenPayload } from "@/dtos/access-token-payload.dto";
import {
  doesSessionExist,
  getAccessTokenPayloadSecurely,
} from "supertokens-web-js/recipe/session";

export const useAccessTokenPayload = async () => {
  let payload: AccessTokenPayload | null = null;

  if (await doesSessionExist()) {
    payload = (await getAccessTokenPayloadSecurely()) as AccessTokenPayload;
  }

  return payload;
};
