import type { MaybeRefOrGetter } from "vue";
import { api } from "@/utils/api.util";
import { RateSchema } from "@/utils/pricing.util";
import { useThrottleFn } from "@vueuse/core";
import { DateTime } from "luxon";
import { z } from "zod";
import { doesSessionExist } from "supertokens-web-js/recipe/session";

const BalanceSchema = z.object({
  max: z.number().gte(0),
  remaining: z.number().gte(0),
  resetAt: z
    .string()
    .datetime({ offset: true })
    .transform((value) => DateTime.fromISO(value)),
});

export type Balance = z.output<typeof BalanceSchema>;

const UserBalancesSchema = z.record(RateSchema, BalanceSchema);
type UserBalancesDto = z.input<typeof UserBalancesSchema>;
export type UserBalances = z.output<typeof UserBalancesSchema>;

export async function fetchBalances() {
  const data = await api<UserBalancesDto>("/me/balances");
  return UserBalancesSchema.parse(data);
}

export function useBalances() {
  const balances = ref<UserBalances>();

  const refresh = useThrottleFn(async () => {
    balances.value = await fetchBalances();
  }, 500);

  (async () => {
    if (!(await doesSessionExist())) return;

    await refresh();

    const diffs = Object.values(balances.value!)
      .map((b) => b.resetAt.diffNow().milliseconds)
      .filter((ms) => ms > 0);

    if (diffs.length) {
      const nearestResetMs = Math.min(...diffs);
      setTimeout(refresh, nearestResetMs);
    }
  })();

  return { balances, refresh };
}

export function useBalanceCountdown(
  balanceRef: MaybeRefOrGetter<Balance | undefined>,
) {
  const countdown = ref<string>();

  let countdownInterval: number | undefined;

  function updateCountdown(balance: Balance) {
    const diff = balance.resetAt.diffNow();

    if (diff.toMillis() > 0) {
      countdown.value = diff.toFormat("hh:mm:ss");
    } else if (countdownInterval) {
      clearInterval(countdownInterval);
    }
  }

  watch(
    () => toValue(balanceRef),
    (balance) => {
      if (countdownInterval) {
        clearInterval(countdownInterval);
      }

      if (!balance || balance.remaining > 0) {
        countdown.value = undefined;
        return;
      }

      // update countdown immediately
      updateCountdown(balance);

      // then sync with the clock to the nearest second
      setTimeout(() => {
        countdownInterval = window.setInterval(() => {
          // and update it every second
          updateCountdown(balance);
        }, 1000);
      }, 1000 - new Date().getMilliseconds());
    },
    { immediate: true },
  );

  return countdown;
}
