import type { App } from "vue";
import { init } from "@sentry/vue";

export function initSentry(app: App) {
  return init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    normalizeDepth: 7,
  });
}
