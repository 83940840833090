import { createRouter, createWebHistory } from "vue-router";
import { Routes } from "./routes.enum";
import { getLinkCodeFromURL } from "supertokens-web-js/recipe/passwordless";
import { useMapStore } from "@/stores/map.store";
import { doesSessionExist } from "supertokens-web-js/recipe/session";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      name: Routes.SPACE,
      redirect: { name: Routes.SPACE_MAP },
      component: () => import("@/views/SpaceView.vue"),
      children: [
        {
          path: "",
          name: Routes.SPACE_MAP,
          component: () => import("@/views/space/SpaceMapView.vue"),
          meta: {
            heightScreen: true,
            disableFooter: true,
          },
        },
        {
          path: "export",
          name: Routes.SPACE_EXPORT,
          component: () => import("@/views/space/SpaceExportView.vue"),
          beforeEnter: () => {
            const mapStore = useMapStore();
            if (!mapStore.computeResponse || !mapStore.pricing) {
              return { name: Routes.SPACE };
            }
          },
          meta: {
            heightScreen: true,
            heightScreenFrom: "lg",
          },
        },
        {
          path: "history",
          name: Routes.SPACE_HISTORY,
          component: () => import("@/views/space/SpaceHistoryView.vue"),
          beforeEnter: async () => {
            if (!(await doesSessionExist())) {
              return { name: Routes.SPACE };
            }
          },
          meta: {
            heightScreen: true,
            disableFooter: true,
          },
        },
        {
          path: "plans",
          name: Routes.SPACE_PLANS,
          component: () => import("@/views/space/SpacePlansView.vue"),
        },
        {
          path: "about",
          name: Routes.SPACE_ABOUT,
          component: () => import("@/views/space/SpaceAboutView.vue"),
        },
      ],
    },
    {
      path: "/auth",
      name: Routes.AUTH,
      component: () => import("@/views/AuthView.vue"),
      redirect: { name: Routes.AUTH_LOGIN },
      children: [
        {
          path: "login",
          name: Routes.AUTH_LOGIN,
          beforeEnter: async () => {
            return (await doesSessionExist()) ? { name: Routes.SPACE } : true;
          },
          component: () => import("@/views/auth/AuthLoginView.vue"),
        },
        {
          path: "check-email",
          name: Routes.AUTH_CHECK_EMAIL,
          beforeEnter: (to, from) => {
            return from.name === Routes.AUTH_LOGIN || { name: Routes.SPACE };
          },
          component: () => import("@/views/auth/AuthCheckEmailView.vue"),
        },
        {
          path: "verify",
          name: Routes.AUTH_VERIFY,
          beforeEnter: () => {
            if (!getLinkCodeFromURL()) {
              return { name: Routes.SPACE };
            }
          },
          component: () => import("@/views/auth/AuthVerifyView.vue"),
        },
        {
          path: "callback/google",
          name: Routes.AUTH_GOOGLE_CALLBACK,
          component: () => import("@/views/auth/AuthGoogleCallback.vue"),
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: { name: Routes.SPACE },
    },
  ],
});

export default router;
