import type { LngLat } from "maplibre-gl";

export type Point = { x: number; y: number };

export function epsg4326to3857(coordinates: LngLat): Point {
  const x = (coordinates.lng * 20037508.34) / 180;
  const y =
    ((Math.log(Math.tan(((90 + coordinates.lat) * Math.PI) / 360)) /
      (Math.PI / 180)) *
      20037508.34) /
    180;

  return { x, y };
}

export function cartesianDistance(point1: Point, point2: Point) {
  return Math.sqrt(
    Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2),
  );
}
