<template>
  <NConfigProvider
    preflight-style-disabled
    inline-theme-disabled
    :theme-overrides="THEME_OVERRIDES"
    :locale="naiveLocale"
    :date-locale="naiveDateLocale">
    <NLoadingBarProvider>
      <NMessageProvider :duration="5000">
        <NNotificationProvider>
          <NDialogProvider>
            <Suspense>
              <AppContent />
            </Suspense>
          </NDialogProvider>
        </NNotificationProvider>
      </NMessageProvider>
    </NLoadingBarProvider>
  </NConfigProvider>
</template>

<script lang="ts" setup>
import {
  NNotificationProvider,
  dateFrFR,
  frFR,
  dateEnUS,
  enUS,
} from "naive-ui";
import { THEME_OVERRIDES } from "@/constants/theme.constant";
import AppContent from "@/AppContent.vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n({ useScope: "global" });

const naiveLocale = computed(() => (locale.value === "fr" ? frFR : enUS));
const naiveDateLocale = computed(() =>
  locale.value === "fr" ? dateFrFR : dateEnUS
);
</script>
