import { defineStore } from "pinia";
import { type ComputeResponseDto } from "@/constants/export-options.constant";
import Big from "big.js";
import { useArea } from "@/composables/area.composable";
import {
  useAvailableDatasets,
  useLayers,
  useRegion,
} from "@/composables/region.composable";
import { usePricing } from "@/composables/pricing.composable";
import { useBalances } from "@/composables/balances.composable";
import { useMapContext } from "@/composables/map-context.composable";

export const useMapStore = defineStore("map", () => {
  const { bbox: settledBbox, zoom: settledZoom, selection } = useMapContext();

  const bbox = shallowRef(settledBbox.value);
  const zoom = ref(settledZoom.value);

  const area = useArea(bbox);
  const region = useRegion(settledBbox);
  const availableDatasets = useAvailableDatasets(
    settledBbox,
    () => region.value?.datasets,
  );
  const availableLayers = useLayers(availableDatasets);

  const { balances, refresh: refreshBalances } = useBalances();

  const pricing = usePricing(
    area,
    () => (region.value ? Big(region.value.priceRatio) : undefined),
    selection,
    balances,
  );

  const computeResponse = ref<ComputeResponseDto>();

  return {
    bbox,
    settledBbox,
    zoom,
    settledZoom,
    area,
    region,
    availableDatasets,
    availableLayers,
    selection,
    balances,
    refreshBalances,
    pricing,
    computeResponse,
  };
});
